import React from 'react'
import ServicesRoutes from '../../ServicesRoutes'

const Services = () => {
  return (
    <div>
        <ServicesRoutes/>
    </div>
  )
}

export default Services
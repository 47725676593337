import React, { useEffect, useState, useRef } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Checkbox, Typography, Alert } from "@material-tailwind/react";
import { CiCircleRemove } from "react-icons/ci";
import { AiOutlineDown } from "react-icons/ai"; 
import { useDispatch, useSelector } from "react-redux";
import { addPqr, updatePQRCount, editPQR } from "../../features/Tender/TenderSlice";
import { fetchPqrList } from "../../features/TenderDetail/TenderDetailSlice";


const SelectPqrModal = ({ pqrModalStatus, setPqrModalStatus, tenderId, selectedPqrModal, pqrVal }) => {
  const handleOpen = (value) => setPqrModalStatus(value);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  
  const { pqrListData, pqrListStatus, error } = useSelector(state => state.tenderDetail);
  const { pqr_list, pqrfetchStatus } = useSelector(state => state.tender);

  

  useEffect(() => {
    if (!pqrModalStatus) {
      resetValues();
    } else if (pqrModalStatus && pqrListStatus === "idle") {
      dispatch(fetchPqrList()); // Fetching PQR List from the API
    }
  }, [pqrModalStatus, pqrListStatus, dispatch]);

  useEffect(() => {
    if(selectedPqrModal === pqrVal.editPqr && pqrfetchStatus==="succeeded") {
      setSelectedOptions(pqr_list)
    }
  }, [selectedPqrModal, pqrfetchStatus])


  const resetValues = () => {
    setSelectedOptions([]);
    setIsDropdownOpen(false);
    setShowAlert(false);
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else if (selectedPqrModal === pqrVal.editPqr) {
      setIsDropdownOpen(false);
      updatePqrApi();
    } else {
      setIsDropdownOpen(false);
      addPqrApi();
    }
  };

  const handleUpdate = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else {
      setIsDropdownOpen(false);
      updatePqrApi();
    }
  };

  const addPqrApi = async () => {
    try {
      const payload = {
        "doc_id": tenderId,
        "pqr": selectedOptions,
      };
      await dispatch(addPqr(payload));
      dispatch(updatePQRCount({ doc_id: tenderId, new_count: selectedOptions.length, flag: "add_pqr" }));
      setPqrModalStatus(false);
    } catch (error) {
      setShowAlert(true);
      console.error(error);
    }
  };

  const updatePqrApi = async () => {
    try {
      const payload = {
        "doc_id": tenderId,
        "pqrs": selectedOptions,
      };
      await dispatch(editPQR(payload));
      dispatch(updatePQRCount({ doc_id: tenderId, new_count: selectedOptions.length, flag: "edit_pqr" }));
      setPqrModalStatus(false);
    } catch (error) {
      setShowAlert(true);
      console.error(error);
    }
  };

  const handleOptionClick = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const selectAll = () => {
    if (selectedOptions.length === pqrListData.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(pqrListData.map((item) => item));
    }
  };

  const NoData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      No PQR Selected
    </div>
  );

  const LoadingData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      Loading Data
    </div>
  );

  const FailedData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      Failed to fetch Data
    </div>
  );

  const SelectedPqrList = () => (
    <div className="flex flex-col gap-1 shadow-lg border-gray-700 border overflow-y-scroll h-[40vh] pl-3">
      <div className="w-full flex items-center justify-center py-2 text-black text-lg font-bold">Selected PQRS</div>
      {selectedOptions.map((item, index) => (
        <div key={index} className="flex items-center justify-between">
          <Typography color="gray">{item}</Typography>
          <Button variant="text" color="red" onClick={() => setSelectedOptions(selectedOptions.filter((option) => option !== item))}>
            <CiCircleRemove size={28} />
          </Button>
        </div>
      ))}
    </div>
  );

  const DropdownList = () => {
    return (
      <div className="border-gray-700 border absolute rounded bg-white divide-y divide-black-700 shadow-lg w-full mt-3 max-h-[42vh] overflow-y-auto">
        <div className="flex items-center p-1 cursor-pointer hover:bg-gray-200 transition duration-200" onClick={selectAll}>
          <Checkbox checked={selectedOptions.length === pqrListData.length} readOnly />
          <span className="ml-2 font-medium">Select all</span>
        </div>
        {pqrListData.map((item, index) => (
          <div
            key={index}
            className={`pl-3 py-3 cursor-pointer hover:bg-gray-200 transition duration-200 font-medium ${selectedOptions.includes(item) ? "bg-gray-200" : ""}`}
            onClick={() => handleOptionClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Dialog open={pqrModalStatus} handler={handleOpen}>
      {showAlert && (
        <Alert open={showAlert} onClose={() => setShowAlert(false)} color="red">
          Choose at least one PQR
        </Alert>
      )}
      <DialogHeader>
        {selectedPqrModal === pqrVal.editPqr ? "Edit PQR" : "Select PQR"}
      </DialogHeader>

      <DialogBody>
        <div className="relative">
          <div className="flex items-center border p-2 rounded cursor-pointer border-gray-700" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="flex flex-wrap gap-1">
              {selectedOptions.length > 0 ? <span style={{fontWeight:"bold"}}>{selectedOptions.length} selected</span> : <span style={{fontWeight:"bold"}}>Select PQR</span>}
            </div>
            <AiOutlineDown className="ml-auto" />
          </div>
          {isDropdownOpen && <DropdownList />}
        </div>
        {pqrListStatus === "loading" ? <LoadingData /> : pqrListStatus === "failed" ? <FailedData /> : selectedOptions.length === 0 ? <NoData /> : <SelectedPqrList />}
      </DialogBody>

      <DialogFooter>
        <Button variant="text" color="red" onClick={() => handleOpen(false)}>
          Cancel
        </Button>
        <Button variant="gradient" color="green" onClick={handleSubmit}>
          {selectedPqrModal === pqrVal.editPqr ? "Update" : "Submit"}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SelectPqrModal;

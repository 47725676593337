import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';

function Logout({logoutStatus, setLogoutStatus}) {
  const navigate = useNavigate()
    const logoutHandler = () => {
      setLogoutStatus(!logoutStatus);
      localStorage.removeItem("Authorization");
      localStorage.removeItem("is_admin");
      navigate('/')
    }
   
    return (
      <>
        <Dialog open={logoutStatus} 
        // handler={logoutHandler}
        >
          <DialogHeader className='flex justify-center text-3xl'>Logout</DialogHeader>
          <DialogBody className='flex justify-center text-[red] text-md'>
            Are you sure you want to logout.
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setLogoutStatus(!logoutStatus)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="green" onClick={logoutHandler}>
              <span>Logout</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
}

export default Logout
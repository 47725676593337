import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { SelectManualModal } from "./SelectManualModal";
import { PortalsModal } from "./PortalsModal";
// import { SelectPqrModal as PortalsModal } from "./SelectPqrModal";    
 
export default function SelectOptions({selectOptionStatus, setSelectOptionStatus}) {
  const [manualModalStatus, setManualModalStatus] = useState(false);
  const [portalsModalStatus, setPortalsModalStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0)


  const handleOpen = (value) => setSelectOptionStatus(value);
  
  const handleManualModal = () =>{
    setManualModalStatus(true);
    setSelectedItem(1)
    setSelectOptionStatus(false);
  }

  const handlePortalsModal = () =>{
    setPortalsModalStatus(true);
    setSelectedItem(2)
    setSelectOptionStatus(false);
  }

  return (
    <>
      <SelectManualModal manualModalStatus={manualModalStatus} setManualModalStatus={setManualModalStatus} setSelectOptionStatus={setSelectOptionStatus}/>
      <PortalsModal portalsModalStatus={portalsModalStatus} setPortalsModalStatus={setPortalsModalStatus} setSelectOptionStatus={setSelectOptionStatus}/>

      <div className="w-48 bg-blue-gray-400">
        <Dialog
          open={selectOptionStatus}
          handler={handleOpen}
        >
          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            Select Options
          </DialogHeader>
          <DialogBody>
            <div className="min-h-1/2">
            <Button onClick={handleManualModal} 
            className={`mb-3 p-1 py-1 text-md font-semibold ${selectedItem === 1 ? 'bg-green-500 border-none text-white' : 'bg-transparent text-[#212121] border border-black'} hover:bg-sky-700 w-full`} >Select Manually</Button>
            <Button onClick={handlePortalsModal} 
            className={`p-1 py-1 text-md font-semibold ${selectedItem === 2 ? 'bg-green-500 border-none text-white' : 'bg-transparent text-[#212121] border border-black'} hover:bg-sky-700 w-full mt-4`} >Select Portals</Button>
            </div>
      
          </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => handleOpen(false)}
                className="mr-3 "
              >
                <span>Cancel</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                onClick={() => handleOpen(false)}
              >
                <span>Confirm</span>
              </Button>
            </DialogFooter>
        </Dialog>
      </div>

    </>
  );
}
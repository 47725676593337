import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../features/Login/UserSlice"
import TenderReducer from "../features/Tender/TenderSlice"
import TenderDetailReducer from "../features/TenderDetail/TenderDetailSlice"
import ManagePQRReducer from "../features/managePqr/ManagePQRSlice"

const store = configureStore({
    reducer: {
        user: UserReducer,
        tender: TenderReducer,
        tenderDetail: TenderDetailReducer,
        managepqr: ManagePQRReducer
    }
})

export default store
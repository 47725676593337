import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
  Input,
  Typography ,
} from "@material-tailwind/react";
import { toast, Toaster } from 'react-hot-toast';
import { MdArrowBackIosNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from "react-router-dom";
import { triggerBot, triggerBotScript } from "../../features/Tender/TenderSlice";


export function PortalsModal({
  portalsModalStatus,
  setPortalsModalStatus,
  setSelectOptionStatus,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const backendLabels = {
    portal: "portal",
    tenderNumber: "tenderNumber",
    userID: "userID",
    password: "password"
  }

  const [loading, setLoading] = useState(false)

  const submitHandler = (e) => {
    e.preventDefault();

    if(selectedItem === null){
      toast.error('Please Select portal')
      return
    }

    const formData = getFormData(e.target.elements)
    if(formData === null){
      toast.error("Please fill all the fields")
      return
    }

    
    callingBotScript(formData)  // Calling Python Script

    // callingBot()  // Calling Automation Script

    
    
  }

  // Function to process form elements and return form data
  const getFormData = (formElements) => {
    const formData = {};
    let emptyValuePresent = false;

    // Loop through each form element and get name and value
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {  
        if (element.value === "" && element.name !== backendLabels.portal) {
          emptyValuePresent = true;
        }
        formData[element.name] = element.value;
      }
    }

    // Add the selected portal to formData manually
    formData[backendLabels.portal] = selectedItem;

    if(emptyValuePresent) {
      return null
    }

    return formData;
  };


  const callingBotScript = async(payload) => {
    const loadingToast = toast.loading('Documents downloading is in progress...')

    try{
        setLoading(true)
        const resultAction = await dispatch(triggerBotScript(payload));
        const data = unwrapResult(resultAction);
        toast.success(<b>Documents Downloaded!</b>, { id: loadingToast });
        closeResetAndNavigateModal()
    }
    catch(error){
      console.log(error)
      // toast.error("Something went wrong. Please try again later")
      toast.error(<b>Something went wrong. Please try again later</b>, { id: loadingToast });
    }
    finally {
      setLoading(false)
    }

  }

  const callingBot = async() => {
    // Automation Script
    try{
      setLoading(true)
      const payload = {
        "bot_id": 13925470
      }

      const resultAction = await dispatch(triggerBot(payload));
      const data = unwrapResult(resultAction);
      const loadingToast = toast.loading('Documents downloading...')
      // Wait for 5 seconds
      setTimeout(() => {
        toast.success(<b>Documents Downloaded!</b>, { id: loadingToast });
      }, 15000);
      
      closeResetAndNavigateModal()
    }
    catch(error){
      console.log(error)
      toast.error("Something went wrong. Please try again later")
    }
    finally {
      setLoading(false)
    }
  }

  const closeResetAndNavigateModal = () => {
    // Close Modal & Reset Form
    setPortalsModalStatus(false)
    setSelectedItem(null)

    // Navigate to home page
    navigate("/all-tenders")
  }

  const cancelHandler = () => {
    setSelectedItem(null)
    setPortalsModalStatus(false)
  }

  


  const handleBack = () => {
    setPortalsModalStatus(false);
    setSelectOptionStatus(true);
  };

  const data = [
    {
      portal: "Government e-Marketplace (GeM)",
    },
    // {
    //   portal: "National Informatics Centre (NIC) eProcurement System",
    // },
    // {
    //   portal: "Indian Oil Corporation Limited (IOCL) e-Tendering Portal",
    // },
    // {
    //   portal: "Tendermitra Portal",
    // },
  ];

  return (
    <>
      <Dialog open={portalsModalStatus} 
      dismiss={{
        outsidePress: false,
      }}
      >
        <Toaster position="top-center" />

        <div className="flex items-center justify-start gap-40">

          <div onClick={handleBack} className="cursor-pointer ml-2">
            <MdArrowBackIosNew  size={25}/>
          </div>

          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            Select Portal
          </DialogHeader>
        </div>

        <form onSubmit={submitHandler}>
        <DialogBody className="cursor-pointer">
          <Select label="Select Portal" name={backendLabels.portal} onChange={(value) => setSelectedItem(value)}>
            {data.map((item, index) => (
              <Option key={index} value={item.portal}>
                {item.portal}
              </Option>
            ))}
          </Select>

          <div className="mt-8 flex flex-col gap-8 text-black">
            <div>
              <Input label="Enter User ID" className="text-black" name={backendLabels.userID}/>
            </div>
            <div >
              <Input type="password" label="Password" name={backendLabels.password}/>
            </div>
            <div>
              <Input label="Enter Tender Number" name={backendLabels.tenderNumber}/>
            </div>
          </div>
        </DialogBody>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={cancelHandler}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            // onClick={submitHandler}
            type="submit"
            loading={loading}
          >
            <span>Submit</span>
          </Button>
        </DialogFooter>
        </form>

      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Input, Form } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { addNewPqr, editPqr, deletePqr } from '../../features/managePqr/ManagePQRSlice'; 
import { fetchPqrList } from '../../features/TenderDetail/TenderDetailSlice';
import { setActiveTenderType } from '../../features/TenderDetail/TenderDetailSlice';

const ManagePQR = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingPqr, setEditingPqr] = useState(null);
  const [deletingPqr, setDeletingPqr] = useState(null);

  const { pqrListData, pqrListStatus, totalPqrCount } = useSelector((state) => state.tenderDetail);

  useEffect(()=>{
    dispatch(setActiveTenderType(""));
 },[])

  useEffect(() => {
    dispatch(fetchPqrList());
  }, [dispatch]);

  const showModal = (pqr) => {
    setIsModalVisible(true);
    if (pqr) {
      setEditingPqr(pqr);
      form.setFieldsValue({ pqrName: pqr });
    } else {
      setEditingPqr(null);
      form.resetFields();
    }
  };

  const showDeleteModal = (pqr) => {
    setDeletingPqr(pqr);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = () => {
    const token = localStorage.getItem('Authorization');
    dispatch(deletePqr({ index: pqrListData.indexOf(deletingPqr), token }))
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        const token = localStorage.getItem('Authorization');
        setLoading(true);
        if (editingPqr) {
          dispatch(editPqr({ index: pqrListData.indexOf(editingPqr), new_pqr_name: values.pqrName, token }))
            .unwrap()
            .then(() => {
              setIsModalVisible(false);
              form.resetFields();
              window.location.reload();
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          dispatch(addNewPqr({ pqr_name: values.pqrName, token }))
            .unwrap()
            .then(() => {
              dispatch(fetchPqrList());
              setIsModalVisible(false);
              form.resetFields();
            })
            .catch(() => {
              setLoading(false);
            });
        }
      })
      .catch(info => {
        console.log('Validation Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const columns = [
    {
      title: 'PQR List',
      dataIndex: 'pqr',
      key: 'pqr',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex gap-4">
          <Button icon={<EditOutlined />} type="primary" onClick={() => showModal(record.pqr)} />
          <Button
            icon={<DeleteOutlined style={{ color: 'white' }} />}
            style={{
              backgroundColor: 'red',
              border: 'none',
              color: 'white',
            }}
            onClick={() => showDeleteModal(record.pqr)} // Trigger delete modal
          />
        </div>
      ),
    },
  ];

  const dataSource = Array.isArray(pqrListData) ? pqrListData.map((pqr, index) => ({
    key: index + 1,
    pqr,
  })) : [];

  return (
    <div className="w-full h-full bg-[#FFFFFF] flex flex-col items-center justify-center gap-6 border border-blue-gray-50 shadow-xl rounded-[10px] p-8">
      <div className="flex justify-between w-full">
        <h1 className="text-3xl  text-[#8d6641]">Manage PQR</h1>
        <div className='flex items-center justify-center gap-5'>
          <h1 className='font-bold text-[#33ACFF]'>Total PQR: {totalPqrCount || 0}</h1>
          <Button
            type="primary"
            style={{
              backgroundColor: 'rgb(209, 236, 254)',
              borderRadius: '20px',
              border: 'none',
              color: 'black',
            }}
            icon={<PlusOutlined />}
            onClick={() => showModal(null)}
          >
            Add PQR
          </Button>
        </div>
      </div>

    
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          className="managepqr-container"
          sticky // Make the header sticky
          loading={pqrListStatus === 'loading'}
         
        />
      

      {/* Add/Edit PQR Modal */}
      <Modal
        title={editingPqr ? "Edit PQR" : "Add PQR"}
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="pqrForm">
          <Form.Item
            name="pqrName"
            label="PQR Name"
            rules={[{ required: true, message: 'Please enter the PQR name!' }]}
          >
            <Input placeholder="Enter PQR name" style={{ width: '100%' }}/>
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Delete PQR"
        centered
        visible={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={handleDeleteCancel}
        footer={[
          <Button key="cancel" onClick={handleDeleteCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" style={{ backgroundColor: 'red', borderColor: 'red' }} onClick={handleDelete}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this PQR?</p>
      </Modal>
    </div>
  );
};

export default ManagePQR;

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Alert
} from "@material-tailwind/react";
import { Spinner } from "@material-tailwind/react";

import { useDispatch,useSelector } from "react-redux";

const ViewSelectedPqrModal = ({ viewPqrModalStatus, setViewPqrModalStatus, pqrList}) => {
  const handleOpen = (value) => setViewPqrModalStatus(value);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { pqr_list , pqrfetchStatus} = useSelector(state => state.tender);

  const [alertMsg, setAlertMsg] = useState("")

  useEffect(() => {
    if(showAlert){
      setTimeout(() => {
        setShowAlert(false)
      }, 4000)
    }
  }, [showAlert])


  useEffect(() => {
    if(showAlert){
      setTimeout(() => {
        setShowAlert(false)
      }, 4000)
    }
  }, [showAlert])

  // Home & View Detail Page
  useEffect(() => {
    if(!viewPqrModalStatus && !pqrList){
      setSelectedOptions([])
    }
  }, [viewPqrModalStatus])

  

  // View Detail Page
  useEffect(() => {
    if(pqrList){
      setSelectedOptions(pqrList)
    }
   }, [pqrList])


  // Home Page
  useEffect(()=>{
    if(pqrfetchStatus==="succeeded" && !pqrList){
        setSelectedOptions(pqr_list);
    }
 },[pqrfetchStatus, pqrList])

 

  const NoData = () => {
    return (
      <div className="h-[40vh] flex items-center justify-center">
       <Spinner color="blue" className="h-10 w-10" />
      </div>
    )
  }

  const SelectedPqrList = () => {
    return (
      <div className={`flexabsolute rounded bg-white divide-y divide-black-700 mt-0 flex flex-col gap-1 shadow-lg border-gray-700 border overflow-y-scroll max-h-[50vh]  pl-3`}>
              {selectedOptions.map((item, index) => (
                <div key={index} className="flex items-center justify-between py-3">
                  <Typography color="gray">{item}</Typography>
                </div>
              ))}
      </div>
    )
  }

  const toggleRef = useRef(null);

  useEffect(() => {
    selectedOptions.length > 0 && setShowAlert(false);
  }, [selectedOptions]);

  return (
    <>
      <Dialog
        // className= "min-h-[60vh]"
        open={viewPqrModalStatus}
        handler={handleOpen}
        // dismiss={{
        //   outsidePress: false,
        // }}
      >
        {showAlert && (
            <Alert
              open={showAlert}
              onClose={() => setShowAlert(false)}
              color="red"
              animate={{
                mount: { y: 0 },
                unmount: { y: 100 },
              }}
          > 
            {
              alertMsg.length>0 ? alertMsg : "Choose atleast one PQR"
            }
          </Alert>
          )}

        <div className="flex items-center justify-center gap-40">
          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            Selected PQRS
          </DialogHeader>
        </div>

        <DialogBody className="">

          {
            selectedOptions.length === 0 ?
            <NoData/>
            :

            <SelectedPqrList />
          
        }

        </DialogBody>

        <DialogFooter className="">
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(false)}
            className=" mr-1  "
          >
            <span>Cancel</span>
          </Button>
          
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ViewSelectedPqrModal;

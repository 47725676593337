// Cloud run
const hostname= "https://iocl-backend-j7no7yv4kq-el.a.run.app"
// localhost
// const hostname= "http://127.0.0.1:8000"

// Python Automation Script (in VM)
const botScriptHostname = "http://34.100.143.71"

// GenAI Backend 
const genAIBEHostname = "https://iocl-genai-backend-141347240212.asia-south1.run.app"


export {hostname, botScriptHostname, genAIBEHostname}
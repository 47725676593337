import React, { useEffect, useState } from "react";
import HomeTopbar from "../../container/HomeContainer/HomeTopbar";
import HomeTable from "../../container/HomeContainer/HomeTable";
import { fetchTenderDetail } from "../../features/TenderDetail/TenderDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenders } from "../../features/Tender/TenderSlice";
import { DownOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Space, Switch, Dropdown, Menu } from "antd";

function HomePage() {
  const { tenderFecthStatus, tenderArr } = useSelector((state) => state.tender);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllTenders());
  }, []);

  let tenderData = [...tenderArr];

  const [input, setInput] = useState("");
  const [check, setCheck] = useState(true);
  const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState(1);

  

  const handleItemClick = (item) => {
    setCurrentSelectedDropdown(item.key);
  };

  tenderData = tenderData?.filter((val) => {
    if (input === "") {
      return val;
    } 
    else if (
      val.tender_name.toLowerCase().includes(input.toLowerCase()) ||
      val.tender_number.toLowerCase().includes(input.toLowerCase())
    ) {
      return val;
    }
  });

  const dropDownItems = [
    { key: 1, title: "Newest First" },
    { key: 2, title: "Oldest First" },
    { key: 0, title: "Clear" },
  ];

  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => handleItemClick(item)}
          className="search-select-option"
        >
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  // Newest First
  if (currentSelectedDropdown === 1) {
    // url += "&sort_by=desc";
  }
  // Oldest First
 
  if (currentSelectedDropdown === 2) {
    tenderData.reverse();
    // newData = tenderData;
  }

  return (
    <div className="w-full h-full flex flex-col gap-[30px]">
      <div className="flex-none pl-4 pr-4 flex items-center justify-between">
        <div>
          <span className="text-[rgb(141,102,65)] font-bold text-2xl mt-8">
            All Tenders
          </span>
          <div className="flex justify-between gap-4 items-center">
            <Space direction="vertical">
              <Switch
                checkedChildren="TenderName"
                unCheckedChildren="TenderNumber"
                onClick={() => setCheck(!check)}
                defaultChecked
              />
            </Space>
            <Input
              style={{ width: "full" }}
              placeholder={check ? "Search TenderName" : "Search TenderNumber"}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <Dropdown
              className="sorting-filter"
              style={{ width: 120, marginLeft: 16 }}
              overlay={menu}
              trigger={["click"]}
            >
              <Space>
                {currentSelectedDropdown === 0
                  ? "Sort"
                  : currentSelectedDropdown === 1
                  ? <span className="text-base font-bold" style={{ backgroundColor: "#dcedff"}}>Newest First</span>
                  : <span className="text-base font-bold" style={{ backgroundColor: "#dcedff"}}>Oldest First</span>
                  }
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
        </div>
        <span className="text-[#33ACFF] font-bold">
          Count: {tenderArr?.length}
        </span>
      </div>
      <div className="flex-grow w-full overflow-y-auto shadow-xl">
        <HomeTable
          data={tenderData}
          status={tenderFecthStatus}
          type="All Tenders"
        />
      </div>
    </div>
  );
}

export default HomePage;

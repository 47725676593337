import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineEdit } from "react-icons/md";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ReactComponent as DocumentIcon } from "../../Assets/Images/Home/Document.svg"

const ProcessedPQRList = ({
  processedPQR,
  setProcessedPQR,
  processedPQRData,
}) => {
  const handleOpen = () => {
    setProcessedPQR(!processedPQR);
    reset()
  }


  const [handleSourceClick, setHanleSourceClick] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  // const [demoPdfModalStatus, setDemoPdfModalStatus] = useState(false);

  const handleSourceButtonClick = (index) => {
    setHanleSourceClick(!handleSourceClick);
    setActiveIndex(index)
    // setDemoPdfModalStatus(true);
  };

  useEffect(() => {
    if(!handleSourceClick){
      setActiveIndex(null)
    }
  }, [handleSourceClick])

  const reset = () => {
    setHanleSourceClick(false);
    setActiveIndex(null)
  }

  
  const docs = [
    {
      uri:"https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf",
      fileType: "pdf",
      fileName: "Demo.pdf", 
    },
  ];

  return (
    <>
        <Dialog size="xxl" open={processedPQR} handler={handleOpen}>
          
          <DialogHeader className="flex justify-center text-[#8d6641] relative">
            Generated PQR Data
            <RxCross2 onClick={handleOpen} size={30} color="red" className="cursor-pointer absolute right-[2rem]" />
          </DialogHeader>
          <DialogBody className="h-[85vh] ">
            <div className="flex gap-10 h-full">
              <div style={{width:"40%", border:"1px solid #E9EAEC" , padding:"6px", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                <div className="flex max-h-full w-full flex-col gap-6 text-[#242325] text-base font-normal overflow-y-scroll py-4">
                  {processedPQRData?.map((data, index) => {
                    const [key, value] = Object.entries(data)[0];
                    return (
                      <>
                        <div className="flex w-full h-full justify-between items-center gap-y-8 px-4">
                          <div>
                            <span className="font-bold text-[20px]">
                              {" "}
                              {`${key} :` ?? "NA"}
                            </span>
                            <div>{value ?? "NA"}</div>
                          </div>
                          {/* Right colums : source and icons  */}
                          <div className="flex flex-col items-center gap-4">
                            <span
                              className="cursor-pointer"
                              onClick={() => handleSourceButtonClick(index)}
                            >
                              <Button style={{backgroundColor:`${activeIndex === index ? "black" : "rgb(33 150 243)"}`, padding:"6px 16px"}}>Source</Button>
                            </span>
                            {/* Icons  */}
                            <div className="flex space-x-2 items-center gap-2">
                              <IoMdCheckmark size={20} color="green" className="cursor-pointer" />
                              <RxCross2 size={20} color="red" className="cursor-pointer" />
                              <MdOutlineEdit size={20} color="grey" className="cursor-pointer" />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div style={{width:"60%", border:"0.5px solid #E9EAEC", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                {
                handleSourceClick ? 
                <>
                  <div className="flex justify-center items-center w-full max-h-full  overflow-scroll">
                  <DocViewer className="max-h-full" documents={docs} pluginRenderers={DocViewerRenderers}/>
                  </div>
                </>
                : 
                <> 
                  <div className="w-full h-full">
                  <div className=" flex flex-col justify-center items-center w-full h-full font-bold gap-2" >
                    <p className="text-2xl" >You can view Source document here</p>
                    <DocumentIcon style={{width:"8rem"}} />
                    </div>
                  </div>
                </>
                }
         
              </div>
            </div>
          </DialogBody>
          {/* <DialogFooter>
            <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
              <span>Cancel</span>
            </Button>
          </DialogFooter> */}
        </Dialog>
     
    </>
  );
};

export default ProcessedPQRList;

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage/HomePage'
import ViewTenderDetailPage from './pages/ViewTenderDetailPage/ViewTenderDetailPage'
import Sidebar from './Components/Sidebar/Sidebar'
import PendingTendersPage from './pages/PendingTenders/PendingTendersPage'
import InProgressPage from './pages/InProgress/InProgressPage'
import CompletedTenderPage from './pages/CompletedTenderPage/CompletedTenderPage'
import Dashboard from './pages/Dashboard/Dashboard'
import ManagePQR from './pages/ManagePQR/ManagePQR'

const ServicesRoutes = () => {
    const data=[
        {
            path:"/all-tenders",
            element: <HomePage/>
        },
        {
            path:"/manage-pqr",
            element: <ManagePQR/>
        },
        {
            path:"/home",
            element: <Dashboard/>
        },
        {
            path:"/view_detail/:id",
            element: <ViewTenderDetailPage/>
        },
        {
            path:"/completed-tenders",
            element: <CompletedTenderPage/>
        },
        {
            path:"/progress-tenders",
            element: <InProgressPage/>
        },
        {
            path:"/pending-tenders",
            element: <PendingTendersPage/>
        },
        {
            path:"/archived",
            element: <HomePage/>
        }
    ]

  return (
    <div className="bg-[#F6F6F8] w-screen h-screen px-[30px] py-[25px] box-border">
        <div className="w-full h-full flex gap-[25px]">
            <div className='w-[280px] h-full'>
                <Sidebar/>
            </div>
            <div className='h-full'
            style={{
                // border: "1px solid #E5E5E5", 
                width: "calc(100vw - 280px - 25px - 30px - 30px)"}}
            >
             <Routes>
                {data.map((item,key)=>{
                    return <Route path={item.path} element={item.element} key={key}/>
                })}
            </Routes>
            </div>
        </div>
    </div>
  )
}

export default ServicesRoutes
import React, {useState, useRef} from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";  
import { useDispatch, useSelector } from "react-redux"; 
import { useLocation } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import { triggerStatusAPI, triggerGenAI, updateStatus, updatePQRCount, updateTenderCount, deletePendingTender} from "../../features/Tender/TenderSlice";
import { tenderStatus, tenderNextStep } from "../../utils/TenderConstants";
 
const GCSWarningModal=({warningModalStatus, setSelectWarningModalStatus, tenderData , setStatementLoading }) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const confirmationRef = useRef(false);
    if (!tenderData) return null;

    const handleConfirm = () => {
      if (tenderData) {
        generateStatementAPI(tenderData?.doc_id ?? tenderData?.id);
        dispatch(updateTenderCount({flag: "in_progress"}))
        
        if(location.pathname !== "/all-tenders") {
          dispatch(deletePendingTender(tenderData?.doc_id ?? tenderData?.id));
        }
      }
  };

  // console.log(tenderData)

  const generateStatementAPI = async(doc_id) =>{
    setStatementLoading(doc_id);
    try {
      const payload = {
        "document_id": doc_id
      }
      // const result = await dispatch(triggerStatusAPI(payload));
      const result = await dispatch(triggerGenAI(doc_id));
      const data = unwrapResult(result);
      dispatch(updateStatus({doc_id: doc_id, status: tenderStatus.consolidatedStatementGenerating, next_step: tenderNextStep.viewProcess}))
    } 
    catch (error) {
      console.error(error);
    }
    finally {
        setStatementLoading(""); 
    }
  }
   
    const { tender_number, tender_name } = tenderData;

    const handleOpen = (value) => {
        setSelectWarningModalStatus(value);
        if (!value && confirmationRef.current) {
          handleConfirm();
        }
    }
  
  return (
    <>
      <div className="w-48 bg-blue-gray-400">
        <Dialog
          open={warningModalStatus}
          handler={handleOpen}
        >
          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            <div className="flex flex-col items-start w-full gap-6">
              <div className="text-2xl font-bold flex items-center justify-center text-[#8d6641] w-full text-center">Generate Consolidated Statement</div>
              <div>
                <div className="flex items-center text-lg gap-2">
                  <p className="text-gray-600">{`Tender Name:`}</p>
                  <p>{`${tender_name}`}</p>
                </div>
                <div className="flex items-center text-lg gap-2">
                  <p className="text-gray-600">{`Tender Number:`}</p>
                  <p>{`${tender_number ? tender_number : "NA"}`}</p>
                </div>
              </div>
            </div>
          </DialogHeader>
          <DialogBody>
           <p className="text-red-400 font-bold">After clicking this, you won't be able to edit PQRs during this process. Are you sure you want to proceed?</p>
      
          </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => {
                    confirmationRef.current = false;
                    handleOpen(false);
                }}
                className="mr-3 "
              >
                <span>No</span>
              </Button>
              <Button
                            variant="gradient"
                            color="green"
                            onClick={() => {
                                confirmationRef.current = true;
                                handleOpen(false);
                            }}
                        >
                            <span>Yes</span>
                        </Button>
            </DialogFooter>
        </Dialog>
      </div>

    </>
  );
}
export default GCSWarningModal;
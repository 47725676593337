import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Typography, IconButton, Tooltip } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { RxDownload } from "react-icons/rx";
import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { fetchBidderDetail } from "../../features/TenderDetail/TenderDetailSlice";
import ProcessedPQRList from "./ProcessedPQRList";
import { GrValidate } from "react-icons/gr";

const TABLE_HEAD = [
  "Bidder ID",
  "Bidder Name",
  "Total Bidders Documnets",
  "Processing Time",
  "Validate Processed PQRs",
  // "Download Bidder Documents",
  "Accept/Decline/Empty Count"
];

export default function BidderTable(type) {
  // console.log(type.type);

  let tableHeadCopy = [...TABLE_HEAD];

    // if(type.type === "View Report"){
    //     if(true){
    //     tableHeadCopy.splice(3, 0, "Processing Time");
    //     tableHeadCopy.splice(5, 0, "Accept/Decline/Empty");
    //     console.log("Table Head", tableHeadCopy);
    // }

  let { id } = useParams();
  const dispatch = useDispatch();
  const { bidderDetailStatus, bidderDetail } = useSelector(
    (state) => state.tenderDetail
  );

  useEffect(() => {
    dispatch(fetchBidderDetail(id));
  }, [id]);

  const downloadHandler = (data) => {
    // console.log("Download", data)
    const url = data?.bidder_url;
    const link = document.createElement("a");
    link.href = `https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/download-folder/?url=${url}`;
    link.click();
  };

  const [processedPQR, setProcessedPQR] = useState(false);
  const [processedPQRData, setProcessPQRData] = useState([]);
  const viewHandler = (data) => {
    // console.log("Download", data?.bidder_processed);
    if (data?.bidder_processed) {
      setProcessPQRData(data?.pqrs ?? []);
      setProcessedPQR(true);
    }
  };

  const formatProcessingTime = (seconds) => {
    if (seconds < 3600) {
      // If less than 1 hour, return minutes and rounded seconds
      let minutes = Math.floor(seconds / 60);
      let secs = Math.round(seconds % 60); // round to nearest integer
      return `${minutes} minutes ${secs} seconds`;
    } else {
      // If 1 hour or more, return hour minutes and rounded seconds format
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let secs = Math.round(seconds % 60); // round to nearest integer
      return `${hours} hour ${minutes.toString()} minutes ${secs.toString()} seconds`;
    }
}

  const LoadingData = () => {
    // Dummy Array for loading
    const arrayLength = 10;
    const dummyArray = Array.from({ length: arrayLength }, (_, index) => ({
      id: index + 1,
    }));

    return (
      <tbody>
        {dummyArray.map((_, index) => {
          const isLast = index === dummyArray.length - 1;
          const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

          return (
            <tr key={index}>
              <td className={classes}>
                <Skeleton height={25} />
              </td>
              <td className={classes}>
                <Skeleton height={25} />
              </td>
              <td className={classes}>
                <Skeleton height={25} />
              </td>
              <td className={classes}>
                <Skeleton height={25} />
              </td>
              <td className={classes}>
                <Skeleton height={25} />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const NoData = () => {
    return (
      <tbody>
        <tr>
          <td colSpan={tableHeadCopy.length} className="p-4 text-center">
            No Data
          </td>
        </tr>
      </tbody>
    );
  };

  const Data = () => {
    return (
      <tbody>
        {bidderDetail.map((data, index) => {
          const isLast = index === bidderDetail.length - 1;
          const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

          return (
            <tr key={index}>
              <td className={classes}>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {data?.id ?? "NA"}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {data?.bidder_name ?? "NA"}
                  </Typography>
                </div>
              </td>
              <td className={`${classes} `}>
                {data?.num_docs ?? "NA"}
              </td>
              <td className={`${classes} `}>
                {(data?.bidder_processing_time && data?.bidder_processing_time !== "NA")? formatProcessingTime(data?.bidder_processing_time) : "NA"}
              </td>
              <td className={classes}>
                {data?.bidder_processed ? (
                  <IconButton variant="text" onClick={() => viewHandler(data)}>
                    <GrValidate size={20} />
                  </IconButton>
                ) : (
                  <Tooltip content="Processing, please wait until the process is complete!">
                    <IconButton variant="text" onClick={() => viewHandler(data)}>
                      <GrValidate size={20} color={"rgb(128, 128, 128)"}/>
                    </IconButton>
                  </Tooltip>
                )}
              </td>
              <td className={`${classes} `}>
                {data?.count ?? "NA"}
              </td>

              {/* {type.type === "View Report" && (
                <td className={`${classes} `}>{data?.Accept ?? "NA"}</td>
              )} */}

              {/* <td className={classes}>
                <IconButton
                  variant="text"
                  onClick={() => downloadHandler(data)}
                >
                  <RxDownload size={22} />
                </IconButton>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    );
  };

  // craeted TableHead component to reduce repeatability
  const renderTableHeaders = (headers) => {
    return headers.map((head, index) => (
      <th
        key={head}
        className="sticky top-0 cursor-pointer border-b border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
      >
        <Typography
          variant="small"
          color="blue-gray"
          className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
        >
          {head}
          {index !== headers.length - 1 && (
            <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
          )}
        </Typography>
      </th>
    ));
  };

  return (
    <div className="h-full w-full border  flex flex-col overflow-y-auto border-blue-gray-100">
      <ProcessedPQRList
        processedPQR={processedPQR}
        setProcessedPQR={setProcessedPQR}
        processedPQRData={processedPQRData}
      />

      <div className="overflow-x-auto">
        <table className="w-full min-w-max table-auto text-left">
          <thead className="sticky top-0 bg-gray-50 dark:bg-neutral-700 z-10">
            <tr>
              {type.type === "View Report"
                ? renderTableHeaders(tableHeadCopy)
                : renderTableHeaders(TABLE_HEAD)}
            </tr>
          </thead>

          {bidderDetailStatus === "loading" ? (
            <LoadingData />
          ) : bidderDetailStatus === "failed" || bidderDetail.length === 0 ? (
            <NoData />
          ) : (
            <Data />
          )}
        </table>
      </div>
    </div>
  );
}

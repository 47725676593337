// src/features/ManagePQR/ManagePQRSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define initial state
const initialState = {
  pqrList: [],
  status: 'idle',
  error: null,
};

// Async thunk for adding a new PQR
export const addNewPqr = createAsyncThunk(
  'pqr/addNewPqr',
  async ({ pqr_name, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/add_a_new_pqr/',
        { pqr_name },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for editing a PQR
export const editPqr = createAsyncThunk(
  'pqr/editPqr',
  async ({ index, new_pqr_name, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/edit_a_pqr/',
        {
          index,
          new_pqr_name
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting a PQR
export const deletePqr = createAsyncThunk(
  'pqr/deletePqr',
  async ({ index, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/delete_a_pqr/',
        { index }, // Send index in the request body
        {
          headers: {
            Authorization: token, 
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice for handling PQR state
const managePqrSlice = createSlice({
  name: 'pqr',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewPqr.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addNewPqr.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pqrList.push(action.payload);
      })
      .addCase(addNewPqr.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(editPqr.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editPqr.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update the pqrList with the edited PQR
        const index = state.pqrList.findIndex((pqr) => pqr.index === action.payload.index);
        if (index !== -1) {
          state.pqrList[index] = action.payload;
        }
      })
      .addCase(editPqr.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deletePqr.fulfilled, (state, action) => {
        const index = action.meta.arg.index;
        state.pqrList = state.pqrList.filter((_, i) => i !== index); // Filter based on index
        state.totalPqrCount -= 1; // Update total PQR count
      })
      .addCase(deletePqr.rejected, (state, action) => {
        console.error('Failed to delete PQR:', action.payload);
      });
  },
});

export default managePqrSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "../../api/endpoint";
import { makeRequest } from "../../api/api";
import { toast } from 'react-hot-toast';

// Fetch Tender Stats
export const fetchTenderStat = createAsyncThunk(
  "tender/fetchTenderStat",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderStat(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.error ?? "Something went wrong");
      return rejectWithValue(error);
    }
  }
);

// Fetch Bidder Detail
export const fetchBidderDetail = createAsyncThunk(
  "tender/fetchBidderDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.bidderDetail(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to load bidder details");
      return rejectWithValue(error);
    }
  }
);

// Upload Tender Document
export const uploadTenderDocument = createAsyncThunk(
  "tender/uploadTenderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadTenderDocument(id), "POST", data);
      toast.success("Tender document uploaded successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload tender document");
      return rejectWithValue(error);
    }
  }
);

// Upload Existing Bidder Document
export const uploadExistingBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadExistingBidderDocument(id), "PUT", data);
      toast.success("Bidder document updated successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload bidder document");
      return rejectWithValue(error);
    }
  }
);

// Fetch PQR List
export const fetchPqrList = createAsyncThunk(
  "tender/fetchPqrList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.pqrList, "GET");
      return {
        pqrNames: response.data.pqr_names, // Getting PQR names
        count: response.data.count,         // Getting the count
      };
    } catch (error) {
      toast.error(error.message ?? "Failed to load PQR list");
      return rejectWithValue(error);
    }
  }
);

// Upload New Bidder Document
export const uploadNewBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadNewBidderDocument(id), "POST", data);
      toast.success("New bidder document uploaded successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload new bidder document");
      return rejectWithValue(error);
    }
  }
);

// Fetch Tender Info
export const fetchTenderInfo = createAsyncThunk(
  "tender/fetchTenderInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderInfo(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to load tender information");
      return rejectWithValue(error);
    }
  }
);

// Download Folder
export const downloadFolder = createAsyncThunk(
  "tender/downloadfolder",
  async (url, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.downloadFolder(url), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to download folder");
      return rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  tenderStat: "idle",
  tenderStatDetail: {},
  bidderDetailStatus: "idle",
  bidderDetail: [],
  tenderInfo: "idle",
  tenderInfoDetail: {},
  activeTenderType: '',
  pqrListStatus: "idle",
  pqrListData: [],
};

// Slice
const tenderDetailSlice = createSlice({
  name: "tenderDetail",
  initialState,
  reducers: {
    updateTenderDocCount: (state, action) => {
      state.tenderStatDetail.total_tender_documents = action.payload;
      state.tenderInfoDetail.num_tender_docs = action.payload;
    },
    updateExistingBidderCount: (state, action) => {
      const { id, num_docs } = action.payload;
      const bidder = state.bidderDetail.find(bidder => bidder.id === id);
      if (bidder) {
        bidder.num_docs = num_docs;
      }
    },
    updateNewBidder: (state, action) => {
      state.bidderDetail.push(action.payload);
      if (state.tenderStatDetail.total_bidder) {
        state.tenderStatDetail.total_bidder += 1;
      }
    },
    setActiveTenderType: (state, action) => {
      state.activeTenderType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Tender Stat
      .addCase(fetchTenderStat.pending, (state) => {
        state.tenderStat = "loading";
        state.tenderStatDetail = {};
      })
      .addCase(fetchTenderStat.fulfilled, (state, action) => {
        state.tenderStat = "succeeded";
        state.tenderStatDetail = action.payload ?? {};
      })
      .addCase(fetchTenderStat.rejected, (state) => {
        state.tenderStat = "failed";
        state.tenderStatDetail = {};
      })

      // Fetch Tender Info
      .addCase(fetchTenderInfo.pending, (state) => {
        state.tenderInfo = "loading";
        state.tenderInfoDetail = {};
      })
      .addCase(fetchTenderInfo.fulfilled, (state, action) => {
        state.tenderInfo = "succeeded";
        state.tenderInfoDetail = action.payload;
      })
      .addCase(fetchTenderInfo.rejected, (state) => {
        state.tenderInfo = "failed";
        state.tenderInfoDetail = {};
      })

      // Fetch Bidder Detail
      .addCase(fetchBidderDetail.pending, (state) => {
        state.bidderDetailStatus = "loading";
        state.bidderDetail = [];
      })
      .addCase(fetchBidderDetail.fulfilled, (state, action) => {
        state.bidderDetailStatus = "succeeded";
        state.bidderDetail = action.payload?.bidder_details ?? [];
      })
      .addCase(fetchBidderDetail.rejected, (state) => {
        state.bidderDetailStatus = "failed";
        state.bidderDetail = [];
      })

      // Fetch PQR List
      .addCase(fetchPqrList.pending, (state) => {
        state.pqrListStatus = "loading";
        state.pqrListData = [];
      })
      .addCase(fetchPqrList.fulfilled, (state, action) => {
        state.pqrListStatus = "succeeded";
        state.pqrListData = action.payload.pqrNames; // Use pqrNames from the response
        state.totalPqrCount = action.payload.count;  // Store the count in the state
      })
      .addCase(fetchPqrList.rejected, (state) => {
        state.pqrListStatus = "failed";
        state.pqrListData = [];
      });
  
  },
});

export const {
  updateTenderDocCount,
  updateExistingBidderCount,
  updateNewBidder,
  setActiveTenderType,
} = tenderDetailSlice.actions;

export default tenderDetailSlice.reducer;

import React from 'react'

const UploadFileComp = ({file, setFile, preview, setPreview}) => {

    const renderFilePreview = () => {
        if (!file) return null;
    
        if (file.type.startsWith("image/")) {
          return <img src={preview} alt="File Preview" className="max-w-full h-auto" />;
        } else if (file.type === "application/pdf") {
          return <p className="text-gray-500 place-content-center flex top-2">PDF File: {file.name}</p>;
        } else if (file.type === "application/zip") {
          return <p className="text-gray-500 place-content-center flex top-2">ZIP File: {file.name}</p>;
        } else {
          return <p className="text-gray-500 place-content-center flex top-2">File: {file.name}</p>;
        }
      };

  return (
      <div className="flex items-center justify-center w-full">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                  { file ? renderFilePreview() : <>
                   <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span> or drag and
                    drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    DOCX, PDF , ZIP
                  </p>
                 
                </div>
                
                  </> }
                  <input id="dropzone-file" onChange={(e) => setFile(e.target.files[0])}  type="file" accept=".docx,.pdf,.zip" className="hidden" />     
  
              </label>
            
    </div>
  )
}

export default UploadFileComp

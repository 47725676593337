import React, {useEffect} from 'react';
import welcomeImg from "../../Assets/Images/Home/welcome.jpg";
import { useLocation } from 'react-router-dom';
import { setActiveTenderType } from '../../features/TenderDetail/TenderDetailSlice';
import { useDispatch,useSelector } from 'react-redux';

const Dashboard = () => {
  // Inside your component
const location = useLocation();
const dispatch = useDispatch();
const {activeTenderType} = useSelector((state)=>state.tenderDetail);

  useEffect(()=>{
      dispatch(setActiveTenderType(""));
   },[])

  return (
    <div className="w-full h-full bg-[#FFFFFF] flex flex-col items-center justify-center gap-6 border border-blue-gray-50 shadow-xl rounded-[10px] p-8">
      <div className='flex flex-col gap-6'>
        <h1 className="text-3xl font-extrabold text-center">
          Welcome to the Tender Evaluation Process Web Platform!
        </h1>
        <p className="text-center text-gray-700 font-bold">
          Streamline your tender management with our intuitive platform to enhance efficiency, transparency, and decision-making. From document submissions to automated evaluations, we simplify the process every step of the way.
        </p>
      </div>

      <img src={welcomeImg} alt="welcome" className='w-[40vw]'/>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Switch,
  Typography,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import UploadFileComp from "../UploadFileComp";
import {  uploadNewBidderDoc, uploadTenderDocument, updateTenderDocCount, uploadExistingBidderDoc, updateExistingBidderCount, updateNewBidder} from "../../features/TenderDetail/TenderDetailSlice";
import { toast , Toaster } from "react-hot-toast";

import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

export default function UploadDocument({
  uploadDocumentModalStatus,
  setUploadDocumentModalStatus,
  setDocText,
  doctext,
}) {
  const [alertMsgToShow, setAlertMsgToShow] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [Label, setLabel] = useState("Upload new Bidder document");
  const [selectedBidderId, setSelectedBidderId] = useState("");
  const [loading, setLoading] = useState(false);
  let { id } = useParams();

  const dispatch = useDispatch();

  const { bidderDetail} = useSelector((state) => state.tenderDetail);

  const handleOpen = (value) => {
    setUploadDocumentModalStatus(value);
  };

  const handleSubmitTenderDoc = async(value) => {
    if (file === null) {
      toast.error("Please select a file to upload");
      return;
    }
    const formData = new FormData();
    formData.append("tender_documents", file);
    try {
      setLoading(true);
      const response = await dispatch(uploadTenderDocument({ id, data: formData }));
      const data = unwrapResult(response);
      if(data){
        toast.success("Document uploaded successfully!");
        setFile("");
      }
      setUploadDocumentModalStatus(value);
      dispatch(updateTenderDocCount(data?.num_tender_docs))
    } 
    catch (error) {
      toast.error(error?.error ?? "Something went wrong while uploading document");
    }
    finally{
      setLoading(false);
    }

  };

  const handleSubmitBidderDoc = async(value) => {
    if (file === null) {
      toast.error("Please select a file to upload");
      return;
    }
    const formData = new FormData();
    formData.append("bidder_documents", file);
    try {
      setLoading(true);
      if (!checked) {
        // Existing Bidder
        if(selectedBidderId.length === 0){
          toast.error("Please select existing bidder Name");
          return
        }
        formData.append("id", selectedBidderId);
        const response = await dispatch(uploadExistingBidderDoc({ id, data: formData }));
        const data = unwrapResult(response);
        if(data){
          toast.success("Document Uploaded successfully");
          setFile("");
          dispatch(updateExistingBidderCount({id: data?.id, num_docs: data?.num_docs}))
        }
      } 
      else{
        const response = await dispatch(uploadNewBidderDoc({ id, data: formData }));
        const data = unwrapResult(response);
        if(data){
          toast.success("Document Uploaded successfully");
          setFile("");
          dispatch(updateNewBidder(data?.[0]))
        }
      }
      setUploadDocumentModalStatus(value);
      
      
    } 
    catch (error) {
      toast.error(error?.error ?? "Something went wrong while uploading document");
    } 
    finally{
      setLoading(false);
    }

  };

  useEffect(() => {
    if (alertMsgToShow.length > 0) {
      setTimeout(() => {
        setAlertMsgToShow("");
      }, 4000);
    }
  }, [alertMsgToShow]);

  useEffect(() => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);

      return () => URL.revokeObjectURL(fileURL);
    }
  }, [file]);

  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
    setLabel(
      checked
        ? "Upload New document in Existing Bidder"
        : "Upload New Bidder Document"
    );
  };


  const handleChange = (event) => {
    setSelectedBidderId(event.target.value);
  };

  useEffect(() => {
    if(!uploadDocumentModalStatus){
      reset()
    }
  }, [uploadDocumentModalStatus])

  const reset = () => {
    setChecked(true)
    setFile(null)
    setPreview(null)
    setSelectedBidderId("")
    setLoading(false)
  }

  return (
    <>
      <Dialog
        className="min-h-[60vh]"
        open={uploadDocumentModalStatus}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        dismiss={{
          outsidePress: false,
        }}
      >
         <Toaster position="top-center" />

        {doctext === "Bidder Document" ? (
          <div>
            <DialogHeader className="text-black font-sans subpixel-antialiased text-base relative place-content-center">
              <div className="text-lg place-content-center">{`Upload ${doctext}`}</div>

              <div className="absolute left-6 top-14 font-thin mb-2">
                <Switch
                  color="green"
                  onClick={handleToggle}
                  checked={checked}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {Label}
                      </Typography>
                    </div>
                  }
                  className="text-black"
                />
              </div>
            </DialogHeader>

            <DialogBody className="mt-4">
              {checked ? (
                <UploadFileComp
                  file={file}
                  setFile={setFile}
                  preview={preview}
                  setPreview={setPreview}
                />
              ) : (
                <>
                  <div className="flex flex-col gap-1 mt-2 mb-2">
                    <label className="text-black">Select Existing Bidder Name <span class="text-red-500 text-lg">*</span> </label>
                    <select
                      className="h-7 border-2 w-full mb-4 text-black cursor-pointer"
                      style={{border: "1px solid rgba(0, 0, 0, 0.6)"}}
                      name="existingBidder"
                      id="existingBidder"
                      value={selectedBidderId}
                      onChange={handleChange}
                    >
                      <option className="text-black" value="">Select</option>
                      {bidderDetail.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.bidder_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <UploadFileComp
                    file={file}
                    setFile={setFile}
                    preview={preview}
                    setPreview={setPreview}
                  />
                </>
              )}
            </DialogBody>

            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => handleOpen(false)}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                loading={loading}
                onClick={() => handleSubmitBidderDoc(false)}
              >
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </div>
        ) : (
          <>
            <DialogHeader className="text-black font-sans subpixel-antialiased text-base place-content-center">
              <div className="text-lg">{`Upload ${doctext}`}</div>
            </DialogHeader>
            <DialogBody>
              <UploadFileComp
                file={file}
                setFile={setFile}
                preview={preview}
                setPreview={setPreview}
              />
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => handleOpen(false)}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                loading={loading}
                onClick={() => handleSubmitTenderDoc(false)}
              >
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </>
        )}
      </Dialog>
    </>
  );
}

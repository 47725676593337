import "./App.css";
import "./index.css"
import { Routes, Route } from "react-router";
import LoginPage from "./pages/LoginPage/LoginPage";
import ForgotPassword from "./container/Credentials/ForgotPassword";
import Register from "./container/Credentials/Register";
import Services from "./pages/Services/Services";
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" />

      <Routes>
        <Route path='/*' element={<Services/>} />
        <Route path='/' element={<LoginPage />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/register' element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endpoint from "../../api/endpoint"


export const loginUser = createAsyncThunk("user/loginUser",
    async (userCredentials, { rejectWithValue }) => {
      try {
        const response = await axios.post(endpoint.login, userCredentials);
        return response.data;
      } 
      catch (error) {
        if (error.response && error.response.data?.error) {
            return rejectWithValue(error.response.data.error);
        } 
        else if (error.response && error.response.data) {
            return rejectWithValue('An error occurred while processing your request');
        } 
        else {
            return rejectWithValue('Server error: server not responding');
          }
      }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
    //   builder.addCase(loginUser.fulfilled, (state, action) => {
    //     state = action.payload;
    //     return state;
    //   });
    },
})

export default userSlice.reducer;
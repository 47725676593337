export const tenderStatus = {
    documentDownloaded: "Documents Downloaded",
    pqrSelected: "PQRs Selected",
    consolidatedStatementGenerating: "Consolidated Statement Generating",
    consolidatedStatementGenerated: "Consolidated Statement Generated",
}


export const tenderNextStep = {
    startPqrSelection: "Start PQR Selection",
    generateConsolidatedStatement: "Generate Consolidated Statement",
    viewProgress: "View Report",
    viewProcess: "View Progress"
}

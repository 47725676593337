import React, { useEffect, useState } from 'react'
import { Spinner, Tooltip } from "@material-tailwind/react";
import { BsEye } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { fetchTenderInfo } from '../../features/TenderDetail/TenderDetailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TiPlus } from "react-icons/ti";
import ViewSelectedPqrModal from '../../Components/modals/ViewSelectedPqrModal';
import { ViewDocModal } from '../../Components/modals/ViewDocumentModal';
import UploadDocument from '../../Components/modals/UploadDocument';

const TenderDocument = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { tenderInfo, tenderInfoDetail, tenderStatDetail } = useSelector((state) => state.tenderDetail)
    const [viewModal, setViewModal] = useState(false)
    const [viewDocumentModalStatus, setViewDocumentModalStatus] = useState(false)
    const [docOption, setDocOption] = useState('')

    const [uploadDocumentModalStatus, setUploadDocumentModalStatus] = useState(false)
    const docUrl = tenderInfoDetail?.comparative_statement_url || "";

    const [detail, setDetail] = useState([
        { label: "Selected PQRs", count: null, url: null },
        { label: "Total Tender Documents", count: null, url: null },
        { label: "Comparative Statement", url: null },
        { label: "Technical Queries", url: null }
    ])


    useEffect(() => {
        dispatch(fetchTenderInfo(id))
    }, [])

    useEffect(() => {
        if (tenderInfo === "succeeded" || tenderInfo === "failed") {
            updateTenderInfo()
        }
    }, [tenderInfo, tenderInfoDetail])



    const updateTenderInfo = () => {
        setDetail((prevState) => {
            return prevState.map((stat) => {
                switch (stat.label) {
                    case "Selected PQRs":
                        return { ...stat, count: tenderInfoDetail?.num_pqrs ?? "No Data" }
                    case "Total Tender Documents":
                        return { ...stat, count: tenderInfoDetail?.num_tender_docs ?? "No Data", url: tenderInfoDetail?.tender_details_url }
                    default:
                        return stat
                }
            })
        })
    }


    const handleDownload = (data) => {
        // console.log("Data:", data)
        if (
            tenderStatDetail?.status === "Consolidated Statement Generating" &&
            (data?.label === "Comparative Statement" || data?.label === "Technical Queries")
        ) {
            return;
        }

        if (data?.label === "Comparative Statement") {
            const link = document.createElement("a");
            link.href = docUrl;
            link.download = "comparative-statement.csv";
            link.click();
        }
        else if(data?.label === "Total Tender Documents"){
            const url = data?.url
            console.log(url)
            const link = document.createElement("a");
            link.href = `https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/download-folder/?url=${url}`;
            link.click();
        }
    };

    const viewPqrHandler = (elem) => {
        if (
            tenderStatDetail?.status === "Consolidated Statement Generating" &&
            (elem?.label === "Comparative Statement" || elem?.label === "Technical Queries")
        ) {
            return;
        }
    
        setDocOption(elem?.label === "Total Tender Documents" ? 'docx' : 'excel');
    
        if (elem?.label === "Selected PQRs") {
            setViewModal(true);
        } else {
            setViewDocumentModalStatus(true);
        }
    };

    const formatProcessingTime = (seconds) => {
        if (seconds < 3600) {
          // If less than 1 hour, return minutes and rounded seconds
          let minutes = Math.floor(seconds / 60);
          let secs = Math.round(seconds % 60); // round to nearest integer
          return `${minutes} minutes ${secs} seconds`;
        } else {
          // If 1 hour or more, return hour minutes and rounded seconds format
          let hours = Math.floor(seconds / 3600);
          let minutes = Math.floor((seconds % 3600) / 60);
          let secs = Math.round(seconds % 60); // round to nearest integer
          return `${hours} hour ${minutes.toString()} minutes ${secs.toString()} seconds`;
        }
    }
    



    return (
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-4'>

            <ViewSelectedPqrModal viewPqrModalStatus={viewModal} setViewPqrModalStatus={setViewModal} pqrList={tenderInfoDetail?.pqr_list ?? []}/>
            <UploadDocument uploadDocumentModalStatus={uploadDocumentModalStatus} setUploadDocumentModalStatus={setUploadDocumentModalStatus} doctext={"Bidder Document"} />
            <ViewDocModal viewDocumentModalStatus={viewDocumentModalStatus} setViewDocumentModalStatus={setViewDocumentModalStatus} docOption={docOption} />
            {
                detail.map((elem, i) => (
                    <div key={i} className="flex flex-col justify-between gap-2 bg-[#FFFFFF] border rounded-lg px-4 py-2 shadow-md">
                        {tenderStatDetail.status === "Consolidated Statement Generating" && elem.label !== "Selected PQRs" && elem.label !== "Total Tender Documents" ? (<>
                            <Tooltip content="Not Available while tender evalutaion process is in progress...">
                                <div className='text-[#808080] text-[15px] font-bold'>{elem.label}</div>
                            </Tooltip>
                            <p className='text-[12px]'>
                                Processing Time:
                                <p>(NA)</p>
                            </p>
                        </>): (<>
                            <div className='text-[#242325] text-[15px] font-bold'>{elem.label}</div>
                            { elem.label ==="Technical Queries" && 
                                <p className='text-[12px]'>
                                    Processing Time:
                                    <p>{tenderInfoDetail?.processing_time_Technical_queries ? formatProcessingTime(tenderInfoDetail?.processing_time_Technical_queries) : "NA"}</p>
                                </p>
                            }
                            {elem.label === "Comparative Statement" && 
                                 <p className='text-[12px]'>
                                    Processing Time:
                                    <p>{tenderInfoDetail?.processing_time_comparative_statement ? formatProcessingTime(tenderInfoDetail?.processing_time_comparative_statement) : "NA"}</p>
                                </p>
                            }
                            
                        </>)}
                        
                        <div className='flex items-center justify-between'>
                            <span className='text-[#126568] font-bold text-xl'>{elem.count}</span>
                            <div className='flex gap-4 items-end'>
                                {
                                    elem.label !== "Selected PQRs" && (
                                        
                                         <div
                                            className={`cursor-pointer ${tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? 'cursor-no-drop' : ''}`}
                                            onClick={() => handleDownload(elem)}
                                        >
                                            
                                            <FiDownload size={18} color={tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? "#808080" : "#33363F"} />
                                        </div>
                                         
                                       
                                    )
                                }
                                {
                                    elem.label !== "Total Tender Documents" && (
                                        <div
                                            className={`cursor-pointer ${tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? 'cursor-no-drop' : ''}`}
                                            onClick={() => viewPqrHandler(elem)}
                                        >
                                            <BsEye size={18} color={tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? "#808080" :"#33363F"} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ))
            }

            {/* <div onClick={() => setUploadDocumentModalStatus(true)} className='flex items-center justify-between gap-2 text-base bg-[#E5F7F8] border rounded-lg px-4 py-2 shadow-md cursor-pointer'>
                <TiPlus size={28} />
                <div className='text-center'>Upload Bidder Documents</div>
            </div> */}
        </div>
    )
}

export default TenderDocument
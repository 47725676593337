import React from 'react';
import loginBGPage from "../../Assets/Images/Login/Login_Page.png"

const ForgotPassword = () => {
  return (
    <section class="bg-cover bg-center bg-gray-50 dark:bg-gray-900"
    style={{ backgroundImage: `url(${loginBGPage})` }}
    >
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full p-6 bg-white rounded-lg shadow border md:mt-0 sm:max-w-md">
            <h2 class="mb-1 text-xl font-bold leading-tight text-gray-900 md:text-2xl">
                Change Password
            </h2>
            <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                <div>
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                    <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@company.com" required />
                </div>
                <div>
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900">New Password</label>
                    <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required />
                </div>
                <div>
                    <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900">Confirm password</label>
                    <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required />
                </div>
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input id="newsletter" aria-describedby="newsletter" type="checkbox" class="cursor-pointer w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required />
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="newsletter" class="font-light text-gray-500 flex items-center gap-1">I accept the 
                            <span class="font-medium text-primary-600 hover:underline cursor-pointer">Terms and Conditions</span>
                        </label>
                    </div>
                </div>
                <button type="submit" class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Reset password
                </button>
            </form>
        </div>
    </div>
</section>

  );
};

export default ForgotPassword;
